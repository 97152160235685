import React from "react";
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import LoginForm from "src/views/LoginForm";
import NotFound from "./NotFound";
import AppRoute from "./views/AppRoute";
import AuthenRoute from "./views/AuthenRoute";

const App: React.FC = (props) => {
  return (
    <Router>
      <Switch>
        <Redirect exact path="/" to="/app" />
        <Route exact path="/login" component={LoginForm} />
        <AuthenRoute path="/app" component={AppRoute} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
