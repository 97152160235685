import http from "src/lib/http";
import Constants from "src/utils/constants";

const getUserByUserNamePassword = (userName: string, passWord: string) => {
  return http.get(
    Constants.DEFAULT_URL + "/tUserHead/getUserByUserNamePassword", {
    params: {
      userName,
      passWord
    }
  }
  );
};

const getUserById = (id: number) => {
  return http.get(Constants.DEFAULT_URL + "/tUserHead/" + id);
};

const getUserByEmployeeCode = (empCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tUserHead/getUserByEmployeeCode/" + empCode);
};

const defaultApi = {
  getUserByUserNamePassword,
  getUserById,
  getUserByEmployeeCode,
}
export default defaultApi;