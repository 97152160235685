import {
  AppstoreOutlined, BankOutlined, BarChartOutlined, CarOutlined, DesktopOutlined, FileDoneOutlined, FilePptOutlined, FileTextOutlined, FundProjectionScreenOutlined, IdcardOutlined, LineChartOutlined, OneToOneOutlined, SettingOutlined, SolutionOutlined, TeamOutlined, ToolOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import tGroupPgDetailService from 'src/services/tGroupPgDetailService';
import { useAppSelector } from "src/stores/store";
import "../App.css";

export interface NavItem {
  key: React.Key;
  icon?: React.ReactNode;
  label?: React.ReactNode;
  children?: NavItem[];
}


const initNavItems: NavItem[] = [
  {
    key: 'dashboard',
    icon: <AppstoreOutlined />,
    label: <Link to="/app">Dashboard</Link>,
  },
  {
    key: 'RYWK0011-1',
    icon: <BarChartOutlined />,
    label: <Link to="/app/chart/chartview">Chart</Link>,
  },
  {
    key: 'SAYTEM',
    icon: <SettingOutlined />,
    label: "ข้อมูลระบบ",
    children: [
      {
        key: 'MS0009-1',
        icon: <FilePptOutlined />,
        label: <Link to="/app/bsProvinceH">ข้อมูลจังหวัด</Link>,
      },
      {
        key: 'MS0017-1',
        icon: <FileDoneOutlined />,
        label: <Link to="/app/reference">ข้อมูลอ้างอิง</Link>,
      },
      {
        key: 'msry001-1',
        icon: <BankOutlined />,
        label: <Link to="/app/bankHead">ข้อมูลธนาคาร</Link>,
      },
    ],
  },
  {
    key: 'MASTER',
    icon: <DesktopOutlined />,
    label: "ข้อมูลหลัก",
    children: [
      {
        key: 'nssry014-1',
        icon: <TeamOutlined />,
        label: <Link to="/app/customer">ข้อมูลลูกค้า</Link>,
      },
      {
        key: 'nssry015-1',
        icon: <SolutionOutlined />,
        label: <Link to="/app/debitor">ข้อมูลลูกหนี้</Link>,
      },
      {
        key: 'nssry001-1',
        icon: <IdcardOutlined />,
        label: <Link to="/app/employee">ข้อมูลพนักงาน</Link>,
      },
      {
        key: 'nssry004-1',
        icon: <OneToOneOutlined />,
        label: <Link to="/app/tservice">ข้อมูลบริการ</Link>,
      },
      {
        key: 'nssry003-1',
        icon: <CarOutlined />,
        label: <Link to="/app/tasset">ข้อมูลสินทรัพย์</Link>,
      }
    ],
  },
  {
    key: 'WORKING',
    icon: <ToolOutlined />,
    label: "การปฏิบัติงาน",
    children: [
      {
        key: 'nssry054-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/truckrepair">กำหนดเวลาการซ่อม</Link >,
      },
      {
        key: 'nssry005-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/bill">จัดการบิล</Link >,
      },
      {
        key: 'RYWK0006-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/tConfirmreserveHead">ยืนยันใบจอง</Link>,
      },
      {
        key: 'RYWK0005-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/tCancelreserveHead">ยกเลิกใบจอง</Link>,
      },
      {
        key: 'nssry021-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/truckDischarge">ปล่อยรถ</Link>,
      },
      {
        key: 'nssry051-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/workSheetSteel">จองเหมาแผ่นเหล็ก</Link>,
      },
      {
        key: 'nssry052-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/steelDischarge">ปล่อยแผ่นเหล็ก</Link>,
      },
      {
        key: 'nssry022-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/tAssetmovement">รับรถกลับ</Link>,
      },
      {
        key: 'nssry007-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/worksheetAdvanceDate">เลื่อนการทำงาน</Link>,
      },
      {
        key: 'RW009-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/reportWorksheetFlow">พิมพ์ใบงานประจำวัน</Link>,
      },
    ],
  },
  {
    key: 'FINANCIAL',
    icon: <ToolOutlined />,
    label: "การเงิน",
    children: [
      {
        key: 'nssry037-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/financeReceivable">รับชำระใบงานสด</Link >,
      },
      {
        key: 'RYWK0001Other-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/financeOtherWorksheet">รับชำระจากอื่นๆ</Link >,
      },
      {
        key: 'nssry043-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/financeInsurance">รับชำระเงินประกัน</Link >,
      },
      {
        key: 'nssry039-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/financeReciveableFromRangRad">รับชำระเงินเร่งรัด</Link >,
      },
      {
        key: 'nssry036-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/taxInvoice/list">ออกใบกำกับภาษี</Link >,
      },
    ],
  },
  {
    key: 'TRUSHMONEY',
    icon: <ToolOutlined />,
    label: "ฝากเงินเข้าธนาคาร",
    children: [
      {
        key: 'nssry030-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/trushMoney">ฝากเงินเข้าธนาคาร</Link >,
      },
      {
        key: 'nssry033-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/trushMoneyConfirm">ยืนยันการฝากเงิน</Link >,
      },
    ],
  },
  {
    key: 'RENGRAD',
    icon: <ToolOutlined />,
    label: "เร่งรัด",
    children: [
      {
        key: 'nssry006-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/worksheetReturn">รับใบสั่งงานกลับ</Link >,
      },
      {
        key: 'nssry010',
        icon: <FileTextOutlined />,
        label: <Link to="/app/rengradWangbill">เร่งรัดออกใบแจ้งหนี้</Link >,
      },
      {
        key: 'nssry024',
        icon: <FileTextOutlined />,
        label: <Link to="/app/wangBillReform">ปรับปรุงใบแจ้งหนี้</Link >,
      }
    ],
  },
  {
    key: 'BILLSPACIAL',
    icon: <ToolOutlined />,
    label: "จัดการบิลพิเศษ",
    children: [
      {
        key: 'nssry058-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/creditNote">สร้างใบลดหนี้</Link >,
      },
      {
        key: 'nssry064-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/concludeWorksheet">ตัดจบใบงาน</Link >,
      },
      {
        key: 'nssry066-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/returnFinancePayment">ยกเลิกการรับเงิน</Link >,
      },
      {
        key: 'nssry057-1',
        icon: <FileTextOutlined />,
        label: <Link to="/app/returnForWitholdingTax">ยกเลิกหักภาษี ณ ที่จ่าย</Link >,
      },
    ],
  },
  {
    key: 'REPORT',
    icon: <FundProjectionScreenOutlined />,
    label: "รายงานการปฏิบัติงาน",
    children: [
      {
        key: 'report-1',
        icon: <LineChartOutlined />,
        label: <Link to="/app/">รายงาน 1</Link>,
      },
      {
        key: 'report-2',
        icon: <LineChartOutlined />,
        label: <Link to="/app/">รายงาน 2</Link>,
      },
    ],
  },
];

const SiderNav = () => {
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [programCodes, setProgramCodes] = useState<string[]>(['dashboard', 'report-1', 'report-2']); // default
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const initial = async () => {
    const tGroupPgDetailRes = await tGroupPgDetailService.getMenuByGroupPgCode(userInfo.groupPgCode);
    // console.log("-------- tGroupPgDetailRes : " , tGroupPgDetailRes)
    setProgramCodes([...programCodes, ...tGroupPgDetailRes.data]);
  }

  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    fetchMenus();
  }, [programCodes]);

  const fetchMenus = () => {
    const resultMenus: NavItem[] = [];
    initNavItems.forEach((item) => {
      if (item.children) {
        const subItems = item.children.filter((item) => programCodes.includes(item.key.toString()));
        if (subItems.length > 0) {
          const tempItem = { ...item }
          tempItem.children = subItems;
          resultMenus.push(tempItem);
        }
      } else {
        if (programCodes.includes(item.key.toString())) {
          resultMenus.push(item);
        }
      }
    });
    // console.log(" ====== programCodes : " , programCodes)
    // console.log(" ====== resultMenus : " , resultMenus)
    setNavItems(resultMenus );
  }

  return (
    <Sider
      className="side-menu"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed((prev) => !prev)}
    >
      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        defaultOpenKeys={["sub5"]}
        items={navItems} //NEW
        // items={initNavItems}//SHOW ALL MENU
      />
    </Sider>
  );
};

export default SiderNav;
