import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import AppLayout from "src/layouts/AppLayout";
import Dashboard from "./Dashboard";
import Regex from "./Regex";
import BankHeadList from "./bank/BankHeadList";
import ViewBankHead from "./bank/ViewBankHead";
import BillList from "./bill/BillList";
import ChartView from "./chart/ChartView";
import CreditnoteList from "./creditnote/CreditnoteList";
import ViewCreditnote from "./creditnote/ViewCreditnote";
import CustomerList from "./customer/CustomerList";
import ViewCustomerHead from "./customer/ViewCustomerHead";
import DebitorHeadList from "./debitor/DebitorHeadList";
import ViewDebitorHead from "./debitor/ViewDebitorHead";
import Employee2List from "./employee/Employee2List";
import ViewEmployee2 from "./employee/ViewEmployee2";
import FinanceInsuranceList from "./financeInsurance/FinanceInsuranceList";
import FinanceNonInsurance from "./financeInsurance/FinanceNonInsurance";
import ViewFinanceInsurance from "./financeInsurance/ViewFinanceInsurance";
import FinanceOtherWorksheetList from "./financeOtherWorksheet/FinanceOtherWorksheetList";
import ViewFinanceOtherWorksheet from "./financeOtherWorksheet/ViewFinanceOtherWorksheet";
import FinanceNonReceivable from "./financeReceivable/FinanceNonReceivable";
import FinanceReceivableList from "./financeReceivable/FinanceReceivableList";
import ViewFinanceReceivable from "./financeReceivable/ViewFinanceReceivable";
import FinanceNonReciveableFromRangRad from "./financeReciveableFromRangRad/FinanceNonReciveableFromRangRad";
import FinanceReciveableFromRangRadList from "./financeReciveableFromRangRad/FinanceReciveableFromRangRadList";
import ViewFinanceReciveableFromRangRad from "./financeReciveableFromRangRad/ViewFinanceReciveableFromRangRad";
import TAssetmovementList from "./movement/TAssetmovementList";
import ViewTAssetmovement from "./movement/ViewTAssetmovement";
import BsProvinceHList from "./province/BsProvinceHList";
import ViewBsProvinceH from "./province/ViewBsProvinceH";
import ReferenceList from "./reference/ReferenceList";
import ViewReference from "./reference/ViewReference";
import TRengRadWangBillList from "./rengradWangbill/TRengRadWangBillList";
import ViewTRengRadWangBill from "./rengradWangbill/ViewTRengRadWangBill";
import ReportWorksheetFlowList from "./reportWorksheetFlow/ReportWorksheetFlowList";
import ViewWorkSheetSteelHead from "./steel/ViewWorkSheetSteelHead";
import WorkSheetSteelHeadList from "./steel/WorkSheetSteelHeadList";
import SteelDischargeList from "./steelDischarge/SteelDischargeList";
import ViewSteelDischarge from "./steelDischarge/ViewSteelDischarge";
import TCancelreserveHeadList from "./tCancelreserveHead/TCancelreserveHeadList";
import ViewTCancelreserveHead from "./tCancelreserveHead/ViewTCancelreserveHead";
import TConfirmreserveHeadList from "./tConfirmreserve/TConfirmreserveHeadList";
import ViewTConfirmreserveHead from "./tConfirmreserve/ViewTConfirmreserveHead";
import TAssetHeadList from "./tasset/TAssetHeadList";
import ViewTAssetHead from "./tasset/ViewTAssetHead";
import TaxInvoiceList from "./taxInvoice/TaxInvoiceList";
import ViewTaxInvoice from "./taxInvoice/ViewTaxInvoice";
import TruckDischargeList from "./truckDischarge/TruckDischargeList";
import TruckUnDischargeList from "./truckDischarge/TruckUnDischargeList";
import TruckRepairList from "./truckRepair/TruckRepairList";
import TrushMoneyList from "./trushMoney/TrushMoneyList";
import ViewTrushMoney from "./trushMoney/ViewTrushMoney";
import TrushMoneyConfirmList from "./trushMoneyConfirm/TrushMoneyConfirmList";
import ViewTrushMoneyConfirm from "./trushMoneyConfirm/ViewTrushMoneyConfirm";
import TServiceHeadList from "./tservice/TServiceHeadList";
import ViewTServiceHead from "./tservice/ViewTServiceHead";
import WangBillReform from "./wangBillReform/WangBillReform";
import WorksheetAdvanceDateList from "./worksheetAdvanceDate/WorksheetAdvanceDateList";
import ViewWorksheetReturn from "./worksheetReturn/ViewWorksheetReturn";
import WorksheetReturnList from "./worksheetReturn/WorksheetReturnList";
import ConcludeWorksheetList from "./concludeWorksheet/ConcludeWorksheetList";
import ViewConcludeWorksheet from "./concludeWorksheet/ViewConcludeWorksheet";
import ReturnFinancePaymentList from "./returnFinancePayment/ReturnFinancePaymentList";
import ViewReturnFinancePayment from "./returnFinancePayment/ViewReturnFinancePayment";
import ReturnForWitholdingTaxList from "./returnForWitholdingTax/ReturnForWitholdingTaxList";

const AppRoute: React.FC<RouteComponentProps> = (props) => {
  return (
    <AppLayout>
      <Switch>
        {/* dashboard */}
        <Route exact path="/app/dashboard" component={Dashboard} />

        {/* province */}
        <Route exact path="/app/bsProvinceH/list" component={BsProvinceHList} />
        <Route exact path="/app/bsProvinceH/view/:iid" component={ViewBsProvinceH} />
        <Redirect path="/app/bsProvinceH" to="/app/bsProvinceH/list" />

        {/* bankHead */}
        <Route exact path="/app/bankHead/list" component={BankHeadList} />
        <Route exact path="/app/bankHead/view/:ibankCode" component={ViewBankHead} />
        <Redirect path="/app/bankHead" to="/app/bankHead/list" />

        {/* reference */}
        <Route exact path="/app/reference/list" component={ReferenceList} />
        <Route exact path="/app/reference/view/:id" component={ViewReference} />
        <Redirect path="/app/reference" to="/app/reference/list" />

        {/* chart */}
        <Route exact path="/app/chart/chartview" component={ChartView} />
        <Redirect path="/app/chart" to="/app/chart/chartview" />

        {/* TruckRepair */}
        <Route exact path="/app/truckrepair/list" component={TruckRepairList} />
        <Redirect path="/app/truckrepair" to="/app/truckrepair/list" />

        {/* bill */}
        <Route exact path="/app/bill/list" component={BillList} />
        <Redirect path="/app/bill" to="/app/bill/list" />

        {/* รับชำระจากใบงานสด */}
        <Route exact path="/app/financeReceivable/list" component={FinanceReceivableList} />
        <Route exact path="/app/financeReceivable/view/:financeHead2Id" component={ViewFinanceReceivable} />
        <Route exact path="/app/financeReceivable/create" component={FinanceNonReceivable} />
        <Redirect path="/app/financeReceivable" to="/app/financeReceivable/list" />

        {/* รับชำระจากรายการอื่นๆ */}
        <Route exact path="/app/financeOtherWorksheet/list" component={FinanceOtherWorksheetList} />
        <Route exact path="/app/financeOtherWorksheet/view/:tOtherpaymentHeadId" component={ViewFinanceOtherWorksheet} />
        <Redirect path="/app/financeOtherWorksheet" to="/app/financeOtherWorksheet/list" />

        {/* รับชำระเงินประกัน */}
        <Route exact path="/app/financeInsurance/list" component={FinanceInsuranceList} />
        <Route exact path="/app/financeInsurance/view/:tworksheetInsuranceId" component={ViewFinanceInsurance} />
        <Route exact path="/app/financeInsurance/create" component={FinanceNonInsurance} />
        <Redirect path="/app/financeInsurance" to="/app/financeInsurance/list" />

        {/* รับชำระเงินเร่งรัด */}
        <Route exact path="/app/financeReciveableFromRangRad/list" component={FinanceReciveableFromRangRadList} />
        <Route exact path="/app/financeReciveableFromRangRad/view/:idebitnoteNo" component={ViewFinanceReciveableFromRangRad} />
        <Route exact path="/app/financeReciveableFromRangRad/create" component={FinanceNonReciveableFromRangRad} />
        <Redirect path="/app/financeReciveableFromRangRad" to="/app/financeReciveableFromRangRad/list" />

        {/* ออกใบกำกับภาษี */}
        <Route exact path="/app/taxInvoice/list" component={TaxInvoiceList} />
        <Route exact path="/app/taxInvoice/view/:id" component={ViewTaxInvoice} />
        <Redirect path="/app/taxInvoice" to="/app/taxInvoice/list" />

        {/* ลดหนี้ */}
        <Route exact path="/app/creditNote/list" component={CreditnoteList} />
        <Route exact path="/app/creditNote/view/:id" component={ViewCreditnote} />
        <Redirect path="/app/creditNote" to="/app/creditNote/list" />

        {/* ตัดจบใบงาน */}
        <Route exact path="/app/concludeWorksheet/list" component={ConcludeWorksheetList} />
        <Route exact path="/app/concludeWorksheet/view/:worksheetcode" component={ViewConcludeWorksheet} />
        <Redirect path="/app/concludeWorksheet" to="/app/concludeWorksheet/list" />

        {/* ยกเลิกการรับเงิน */}
        <Route exact path="/app/returnFinancePayment/list" component={ReturnFinancePaymentList} />
        <Route exact path="/app/returnFinancePayment/view/:code" component={ViewReturnFinancePayment} />
        <Redirect path="/app/returnFinancePayment" to="/app/returnFinancePayment/list" />

        {/* ยกเลิกการขอหัก ณ ที่จ่าย */}
        <Route exact path="/app/returnForWitholdingTax/list" component={ReturnForWitholdingTaxList} />
        <Redirect path="/app/returnForWitholdingTax" to="/app/returnForWitholdingTax/list" />

        {/* ฝากเงินเข้าธนาคาร */}
        <Route exact path="/app/trushMoney/list" component={TrushMoneyList} />
        <Route exact path="/app/trushMoney/view/:id" component={ViewTrushMoney} />
        <Redirect path="/app/trushMoney" to="/app/trushMoney/list" />

        {/* ยืนยันการฝากเงิน */}
        <Route exact path="/app/trushMoneyConfirm/list" component={TrushMoneyConfirmList} />
        <Route exact path="/app/trushMoneyConfirm/view/:id" component={ViewTrushMoneyConfirm} />
        <Redirect path="/app/trushMoneyConfirm" to="/app/trushMoneyConfirm/list" />

        {/* รับใบสั่งงานกลับ */}
        <Route exact path="/app/worksheetReturn/list" component={WorksheetReturnList} />
        <Route exact path="/app/worksheetReturn/view/:returnNo" component={ViewWorksheetReturn} />
        <Redirect path="/app/worksheetReturn" to="/app/worksheetReturn/list" />

        {/* เร่งรัดออกใบแจ้งหนี้ */}
        <Route exact path="/app/rengradWangbill/list" component={TRengRadWangBillList} />
        <Route exact path="/app/rengradWangbill/view/:debitnoteNo" component={ViewTRengRadWangBill} />
        <Redirect path="/app/rengradWangbill" to="/app/rengradWangbill/list" />

        {/*ปรับปรุงใบแจ้งหนี้ */}
        <Route exact path="/app/wangBillReform/view" component={WangBillReform} />
        <Redirect path="/app/wangBillReform" to="/app/wangBillReform/view" />

        {/* customer */}
        <Route exact path="/app/customer/list" component={CustomerList} />
        <Route exact path="/app/customer/view/:icustCode" component={ViewCustomerHead} />
        <Redirect path="/app/customer" to="/app/customer/list" />

        {/* debitor */}
        <Route exact path="/app/debitor/list" component={DebitorHeadList} />
        <Route exact path="/app/debitor/view/:iid" component={ViewDebitorHead} />
        <Redirect path="/app/debitor" to="/app/debitor/list" />

        {/* employee */}
        <Route exact path="/app/employee/list" component={Employee2List} />
        <Route exact path="/app/employee/view/:id" component={ViewEmployee2} />
        <Redirect path="/app/employee" to="/app/employee/list" />

        {/* TServiceHead */}
        <Route exact path="/app/tservice/list" component={TServiceHeadList} />
        <Route exact path="/app/tservice/view/:iserviceCode" component={ViewTServiceHead} />
        <Redirect path="/app/tservice" to="/app/tservice/list" />

        { /* TAassetHead */}
        <Route exact path="/app/tasset/list" component={TAssetHeadList} />
        <Route exact path="/app/tasset/view/:id" component={ViewTAssetHead} />
        <Redirect path="/app/tasset" to="/app/tasset/list" />

        {/* tAssetmovement */}
        <Route exact path="/app/tAssetmovement/list" component={TAssetmovementList} />
        <Route exact path="/app/tAssetmovement/view/:seq" component={ViewTAssetmovement} />
        <Redirect path="/app/tAssetmovement" to="/app/tAssetmovement/list" />

        {/* worksheetAdvanceDate */}
        <Route exact path="/app/worksheetAdvanceDate/list" component={WorksheetAdvanceDateList} />
        <Redirect path="/app/worksheetAdvanceDate" to="/app/worksheetAdvanceDate/list" />

        {/* reportWorksheetFlow */}
        <Route exact path="/app/reportWorksheetFlow/list" component={ReportWorksheetFlowList} />
        <Redirect path="/app/reportWorksheetFlow" to="/app/reportWorksheetFlow/list" />

        {/* truckDischarge */}
        <Route exact path="/app/truckDischarge/list" component={TruckDischargeList} />
        <Route exact path="/app/truckDischarge/truckUnDischargeList" component={TruckUnDischargeList} />
        <Redirect path="/app/truckDischarge" to="/app/truckDischarge/list" />

        {/* workSheetSteel */}
        <Route exact path="/app/workSheetSteel/list" component={WorkSheetSteelHeadList} />
        <Route exact path="/app/workSheetSteel/view/:ireserveCode" component={ViewWorkSheetSteelHead} />
        <Redirect path="/app/workSheetSteel" to="/app/workSheetSteel/list" />

        {/* steelDischarge */}
        <Route exact path="/app/steelDischarge/list" component={SteelDischargeList} />
        <Route exact path="/app/steelDischarge/view/:ireserveCode" component={ViewSteelDischarge} />
        <Redirect path="/app/steelDischarge" to="/app/steelDischarge/list" />

        {/* tConfirmreserveHead */}
        <Route exact path="/app/tConfirmreserveHead/list" component={TConfirmreserveHeadList} />
        <Route exact path="/app/tConfirmreserveHead/view" component={ViewTConfirmreserveHead} />
        <Redirect path="/app/tConfirmreserveHead" to="/app/tConfirmreserveHead/list" />

        {/* tCancelreserveHead */}
        <Route exact path="/app/tCancelreserveHead/list" component={TCancelreserveHeadList} />
        <Route exact path="/app/tCancelreserveHead/view" component={ViewTCancelreserveHead} />
        <Redirect path="/app/tCancelreserveHead" to="/app/tCancelreserveHead/list" />

        {/* regex */}
        <Route exact path="/app/regex" component={Regex} />

        {/* other */}
        <Redirect path="/app" to="/app/dashboard" />
      </Switch>
    </AppLayout>
  );
};

export default AppRoute;
