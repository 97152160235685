import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TEmployee2 } from "src/domain/master/tEmployee2";
import { TReferenceDetail } from "src/domain/master/tReferenceDetail";
import { RyRefsizetruck } from "src/domain/ryRefsizetruck";
import { RyTruckwheel } from "src/domain/ryTruckwheel";
import { TTypetruckRef } from "src/domain/tTypetruckRef";
import tEmployee2Service from "src/services/employee/tEmployee2Service";
import ryRefsizetruckService from "src/services/reference/ryRefsizetruckService";
import ryTruckwheelService from "src/services/reference/ryTruckwheelService";
import tReferenceDetailService from "src/services/reference/tReferenceDetailService";
import tTypetruckRefService from "src/services/reference/tTypetruckRefService";
import { useAppSelector } from "src/stores/store";
import { dateToString, timeToString } from "src/utils/dateTimeUtils";
import { formatNumber, parserNumber, textNumber } from "src/utils/numberUtils";
import { ReferenceType } from "src/utils/referenceUtils";
import { TAssetHead } from "../../domain/master/tAssetHead";
import tAssetHeadService from "../../services/tasset/tAssetHeadService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { Option } = Select;
const { confirm } = Modal;

interface IParams {
    id: string;
    visibleAddEditTAssetHeadTab2Drawer: boolean;
    setVisibleAddEditTAssetHeadTab2Drawer: any;
}

const AddEditTAssetHeadTab2Drawer: React.FC<IParams> = (props) => {
    const { id, visibleAddEditTAssetHeadTab2Drawer: visibleTAssetHeadDrawer, setVisibleAddEditTAssetHeadTab2Drawer: setVisibleTAssetHeadDrawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [tAssetHead, setTAssetHead] = useState<TAssetHead>({} as TAssetHead);
    const [form] = Form.useForm();
    const history = useHistory();
    const [showSpin, setShowSpin] = useState<boolean>(false);

    const [assetSubtypes, setAssetSubtypes] = useState<TReferenceDetail[]>([]);
    const [assetSubtypesConstant, setAssetSubtypesConstant] = useState<TReferenceDetail[]>([]);
    const [tTypetruckRefs, setTTypetruckRefs] = useState<TTypetruckRef[]>([]);
    const [ryTruckwheels, setRyTruckwheels] = useState<RyTruckwheel[]>([]);
    const [ryRefsizetrucks, setRyRefsizetrucks] = useState<RyRefsizetruck[]>([]);
    const [drivers, setDrivers] = useState<TEmployee2[]>([]);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    useEffect(() => {
        if (trigger) {
            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        setShowSpin(true);

        initial();
        return () => {
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            trucktype: tAssetHead.trucktype,
            truckregister: tAssetHead.truckregister,
            truckinspeccode: tAssetHead.truckinspeccode,
            truckclassnumber: tAssetHead.truckclassnumber,
            truckcructnumber: tAssetHead.truckcructnumber,
            truckpositioncrutnumber: tAssetHead.truckpositioncrutnumber,
            truckmotornumber: tAssetHead.truckmotornumber,
            truckmotorpositionnumber: tAssetHead.truckmotorpositionnumber,
            truckhostpower: tAssetHead.truckhostpower ? formatNumber(tAssetHead.truckhostpower) : 0.00,
            truckpump: tAssetHead.truckpump ? formatNumber(tAssetHead.truckpump) : 0,
            trucksizecode: tAssetHead.trucksizecode,
            truckwheelcode: tAssetHead.truckwheelcode,
            truckmile: tAssetHead.truckmile,
            assetsubtype: tAssetHead.assetsubtype,
            truckdrivercode: tAssetHead.truckdrivercode
        });

        if (tAssetHead.truckdrivercode) {
            (async () => {
                const res = await tEmployee2Service.getDriverByEmpCode(tAssetHead.truckdrivercode);
                setDrivers(res.data);
            })();
        }
        return () => {
        };
    }, [tAssetHead]);

    const initial = async () => {
        const assetsubtypesRes = await tReferenceDetailService.getTReferenceDetailByCode(ReferenceType.ASSET_SUBTYPE);
        const tTypetruckRefsRes = await tTypetruckRefService.getTTypetruckRefList();
        const ryTruckwheelsRes = await ryTruckwheelService.getRyTruckwheelList();
        const ryRefsizetrucksRes = await ryRefsizetruckService.getRyRefsizetruckList();

        if (id) {
            const res = await tAssetHeadService.getTAssetHeadById(id);
            setTAssetHead(res.data);
        } else {
            const nextCodeRes = await tAssetHeadService.getNextTAssetHeadCode();
            setTAssetHead({ ...tAssetHead, id: nextCodeRes.data });
        }

        setAssetSubtypes(assetsubtypesRes.data);
        setAssetSubtypesConstant(assetsubtypesRes.data);
        setTTypetruckRefs(tTypetruckRefsRes.data);
        setRyTruckwheels(ryTruckwheelsRes.data);
        setRyRefsizetrucks(ryRefsizetrucksRes.data);
        setShowSpin(false);
    };

    const onSearchTextChange = async (searchText: string) => {
        if (searchText.length >= 2) {
            const res = await tEmployee2Service.getDriverByName(searchText);
            setDrivers(res.data);
        }
    }

/*
    01	7-10 ตัน
    02	25 ตัน
    03	35 ตัน
    04	45-50 ตัน
    05	80-120 ตัน
    06	รถบริษัท
    07	ตู้
    08	แผ่นเหล็ก
    09	อื่น ๆ
    10	แรงงาน
    11	เครื่องจักร
    12	รถร่วมนอก

    ASSETTYPE = '001' AND  TRUCKTYPE ='02' AND TRUCKSIZECODE IN ('001','006') --> subtype = 01
    ASSETTYPE = '001' AND  TRUCKTYPE ='02' AND TRUCKSIZECODE IN ('002','007') --> subtype = 02
    ASSETTYPE = '001' AND  TRUCKTYPE ='02' AND TRUCKSIZECODE IN ('009') --> subtype = 03
    ASSETTYPE = '001' AND  TRUCKTYPE ='02' AND TRUCKSIZECODE IN ('008','011') --> subtype = 04
    ASSETTYPE IN ('001','003') AND  TRUCKTYPE ='02' AND TRUCKSIZECODE IN ('005','027','028','029') --> subtype = 05
    ASSETTYPE IN ('001') AND  TRUCKTYPE NOT IN ('02')  --> subtype = 06
    ASSETTYPE IN ('002')   --> subtype = 07
    ASSETTYPE IN ('003')   --> subtype = 08
 */
    const onRenderSubtype = () => {
        const assettype = tAssetHead.assettype;
        const trucktype = form.getFieldValue("trucktype");
        const trucksizecode = form.getFieldValue("trucksizecode");
        if (assettype) {
            if (assettype === '001' && trucktype === "02" && (["001", "006"].indexOf(trucksizecode) > -1)) {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "01" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === '001' && trucktype === "02" && (["002", "007"].indexOf(trucksizecode) > -1)) {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "02" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === '001' && trucktype === "02" && (["009"].indexOf(trucksizecode) > -1)) {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "03" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === '001' && trucktype === "02" && (["008","011"].indexOf(trucksizecode) > -1)) {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "04" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if ((["001","003"].indexOf(assettype) > -1) && trucktype === "02" && (["005","027","028","029"].indexOf(trucksizecode) > -1)) {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "05" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === "001" && trucktype !== "02") {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "06" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === "002") {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "07" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === "003") {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "08" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === "004") {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "10" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === "005") {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "09" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === "006") {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "11" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            } else if (assettype === "007") {
                const array = assetSubtypesConstant.filter((obj) => { return obj.code === "12" });
                setAssetSubtypes(array);
                form.setFieldsValue({assetsubtype: array[0].code});
            }
        } else {
            showNotification(NotificationType.INFO, "กรุณาระบุ ประเภทสินทรัพย์ ก่อน");
        }
    }

    const onFinish = async (values: any) => {
        const _tAssetHead: TAssetHead = {
            ...tAssetHead,
            id: id,
            lastuser: userInfo.userId,
            updatedate: dateToString(new Date()),
            updatetime: timeToString(new Date())
        };
        if (tAssetHead.assettype === "001" || tAssetHead.assettype === "006" || tAssetHead.assettype === "007") {
            _tAssetHead.assetsubtype = values.assetsubtype;
            _tAssetHead.truckclassnumber = values.truckclassnumber;
            _tAssetHead.truckcructnumber = values.truckcructnumber;
            _tAssetHead.truckdrivercode = values.truckdrivercode;
            _tAssetHead.truckhostpower = parserNumber(values.truckhostpower);
            _tAssetHead.truckinspeccode = values.truckinspeccode;
            _tAssetHead.truckmotornumber = values.truckmotornumber;
            _tAssetHead.truckmotorpositionnumber = values.truckmotorpositionnumber;
            _tAssetHead.truckpositioncrutnumber = values.truckpositioncrutnumber;
            _tAssetHead.truckpump = parserNumber(values.truckpump);
            _tAssetHead.truckregister = values.truckregister;
            _tAssetHead.trucksizecode = values.trucksizecode;
            _tAssetHead.trucktype = values.trucktype;
            _tAssetHead.truckwheelcode = values.truckwheelcode;
            _tAssetHead.truckmile = values.truckmile;
        } else {
            _tAssetHead.assetsubtype =  values.assetsubtype;
            _tAssetHead.truckclassnumber = "";
            _tAssetHead.truckcructnumber = "";
            _tAssetHead.truckdrivercode = "";
            _tAssetHead.truckhostpower = 0;
            _tAssetHead.truckinspeccode = "";
            _tAssetHead.truckmotornumber = "";
            _tAssetHead.truckmotorpositionnumber = "";
            _tAssetHead.truckpositioncrutnumber = "";
            _tAssetHead.truckpump = 0;
            _tAssetHead.truckregister = "";
            _tAssetHead.trucksizecode = "000";
            _tAssetHead.trucktype = "00";
            _tAssetHead.truckwheelcode = "0000";
            _tAssetHead.truckmile = 0;
        }

        try {
            await tAssetHeadService.updateTAssetHead(_tAssetHead);
            showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลรายละเอียดเกี่ยวกับรถเรียบร้อยแล้ว!");
            form.resetFields();
            setTrigger(false);
            setVisibleTAssetHeadDrawer(false);
            history.replace("/app/tasset/view/" + tAssetHead.id);
        } catch (err) {
            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
            setTrigger(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        setTrigger(false);
    };

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>แก้ไขข้อมูลรายละเอียดเกี่ยวกับรถ</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleTAssetHeadDrawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleTAssetHeadDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="tAssetHead"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ทะเบียน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={35}>
                                            <Form.Item
                                                name="truckregister"
                                                rules={[
                                                    { required: false },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนทะเบียนให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนทะเบียน" maxLength={100} />
                                            </Form.Item>
                                            <Form.Item
                                                label="รหัสตรวจสภาพรถ: "
                                                name="truckinspeccode"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนรหัสตรวจสภาพรถ" maxLength={100} />
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>หมายเลขรุ่น: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={85}>
                                            <Form.Item
                                                name="truckclassnumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนหมายเลขรุ่น" maxLength={100} />
                                            </Form.Item>
                                            <Form.Item
                                                label="เลขไมล์: "
                                                name="truckmile"
                                                rules={[
                                                    { required: false },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนเลขไมล์ให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    }
                                                ]}
                                            >
                                                <Input type="number" placeholder="ป้อนเลขไมล์" maxLength={8} />
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>หมายเลขตัวรถ/โครงคัท: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={85}>
                                            <Form.Item
                                                name="truckcructnumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนหมายเลขตัวรถ/โครงคัท" maxLength={100} />
                                            </Form.Item>
                                            <Form.Item
                                                label="ตำแหน่ง: "
                                                name="truckpositioncrutnumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนตำแหน่ง" maxLength={250} />
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>หมายเลขเครื่องยนต์: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={85}>
                                            <Form.Item
                                                name="truckmotornumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนหมายเลขเครื่องยนต์" maxLength={100} />
                                            </Form.Item>
                                            <Form.Item
                                                label="ตำแหน่ง: "
                                                name="truckmotorpositionnumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนตำแหน่ง" maxLength={250} />
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ขนาดแรงม้า: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={35}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="truckhostpower"
                                                    rules={[
                                                        { required: false }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        placeholder="ป้อนขนาดแรงม้า"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ truckhostpower: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ truckhostpower: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>แรงม้า</Typography.Text>
                                            </Space>
                                            <Space align="baseline">
                                                <Form.Item
                                                    label="จำนวนสูบ: "
                                                    name="truckpump"
                                                    rules={[
                                                        { required: false }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        placeholder="ป้อนจำนวนสูบ"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ truckpump: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ truckpump: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>สูบ</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ชนิดรถ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="trucktype"
                                            rules={[
                                                { required: true, message: "กรุณาเลือกชนิดรถ!" }
                                            ]}
                                        >
                                            <Select
                                                size="middle"
                                                placeholder="เลือกชนิดรถ"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                                onSelect={onRenderSubtype}
                                            >
                                                {tTypetruckRefs.map((obj) => {
                                                    return (
                                                        <Option key={obj.iid} value={obj.iid}>
                                                            {obj.iname}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ขนาด: </Typography.Text>
                                        <label className="text-red">*</label>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="trucksizecode"
                                            rules={[
                                                { required: false },
                                                {
                                                    validator: (_, value) => {
                                                        if (!value && (tAssetHead.assettype === "001" || tAssetHead.assettype === "006" || tAssetHead.assettype === "007")) {
                                                            return Promise.reject(new Error('กรุณาเลือกขนาด!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                size="middle"
                                                placeholder="เลือกขนาด"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                                onSelect={onRenderSubtype}
                                            >
                                                {ryRefsizetrucks.map((obj) => {
                                                    return (
                                                        <Option key={obj.id} value={obj.id}>
                                                            {obj.tsize}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ประเภทย่อย: </Typography.Text>
                                        <label className="text-red">*</label>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="assetsubtype"
                                            rules={[
                                                { required: true, message: "กรุณาระบุประเภทย่อย" }
                                            ]}
                                        >
                                            <Select
                                                size="middle"
                                                placeholder="เลือกประเภทย่อย"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                            >
                                                {assetSubtypes.map((obj) => {
                                                    return (
                                                        <Option key={obj.code} value={obj.code}>
                                                            {obj.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>                                
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ประเภทล้อ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="truckwheelcode"
                                            rules={[
                                                { required: false },
                                                {
                                                    validator: (_, value) => {
                                                        if (!value && (tAssetHead.assettype === "001" || tAssetHead.assettype === "006" || tAssetHead.assettype === "007")) {
                                                            return Promise.reject(new Error('กรุณาเลือกประเภทล้อ!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                }
                                            ]}
                                        >
                                            <Select
                                                size="middle"
                                                placeholder="เลือกประเภทล้อ"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                            >

                                                {ryTruckwheels.map((obj) => {
                                                    return (
                                                        <Option key={obj.id} value={obj.id}>
                                                            {obj.wheel}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>พนักงานขับรถ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="truckdrivercode"
                                            rules={[
                                                { required: false },
                                                {
                                                    validator: (_, value) => {
                                                        if (!value && (tAssetHead.assettype === "001" || tAssetHead.assettype === "007")) {
                                                            return Promise.reject(new Error('กรุณาเลือกพนักงานขับรถ!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                }]}>
                                            <Select
                                                style={{ width: 200 }}
                                                showSearch
                                                allowClear
                                                size="middle"
                                                placeholder="เลือกพนักงานขับรถ"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                onSearch={onSearchTextChange}
                                            >
                                                {drivers.map((obj) => {
                                                    return (
                                                        <Option key={obj.id} value={obj.id}>
                                                            {obj.empname}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer>
        </>
    );
};

export default AddEditTAssetHeadTab2Drawer;
