import http from "../lib/http";
import Constants from "../utils/constants";

//RW009 = ใบงานประจำวัน
//Get data
const getByCreateDateForSign = (entryDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByCreateDateForSign/" + entryDate);
};

//Print PDF
const getReportByCreateDateForSignPreview = (tWorksheetHeadViews: any) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheetReport/printReportByCreateDateForSignPreview",
    tWorksheetHeadViews,
    {
      responseType: "blob",
    },
  );
};

const defaultApi = {
  getByCreateDateForSign,
  getReportByCreateDateForSignPreview,
};
export default defaultApi;